import React, { useEffect, useState } from "react";
import _ from "lodash";
import UploadFileList from "../components/UploadFileList";
import ContentItem from "../components/ContentItem";
import QUERY_CONTENTS from "../queries/contents";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DOWNLOAD_LINKS_MUTATION } from "../queries/storage";
import { useTranslation } from 'react-i18next';

const ContentPage = () => {

  const { t } = useTranslation();

  const { data, loading, error } = useQuery(QUERY_CONTENTS)
  const [getDownloadLinks] = useMutation(GET_DOWNLOAD_LINKS_MUTATION)
  const [fileUrls, setFileUrls] = useState([])

  useEffect(() => {
    if (error) { console.log(error) }
  }, [error])

  useEffect(() => {
    if (data && !loading) {
      if (data.files.length > 0) {
        const files = data.files.map(({ bucket_id, filename, mimeType }) =>
          ({ bucket_id, filename, mimeType }))
        getDownloadLinks({
          variables: {
            bucketId: files[0].bucket_id,
            fileNames: files.map(file => file.filename)
          },
        })
          .then(({ data: { getDownloadLinks: { urls } } }) => {
            if (urls) {
              const items = _.zip(files, urls).map(item => ({
                fileName: item[0].filename,
                mimeType: item[0].mimeType,
                bucket_id: item[0].bucket_id,
                url: item[1]
              }))
              setFileUrls(() => items)
            }
          })
          .catch(error => console.log(error))
      }
    }
  }, [data, loading, getDownloadLinks])

  return (
    <div id="main">

      <div style={{
        backgroundColor: 'white',
        borderColor: 'white',
        borderBlockWidth: 0,
        fontSize: 30,
        padding: 10,
        fontFamily: 'Kanit',
      }}>
        {t('tb_information.topic')}
      </div>
      <div style={{padding: 10}} />

      <UploadFileList />
      <div className="video-panel">
        {fileUrls.map((item, index) => (
          <ContentItem key={index} src={item.url} id={item.bucket_id} fileName={item.fileName} type={item.mimeType} />
        ))}
      </div>
    </div>
  );
}

export default ContentPage;
