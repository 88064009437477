// import "./RegisterView.css";
import React, { useRef } from "react";
import { FormikProvider, Form, Field, useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStore } from "../../store";
import { useTranslation } from 'react-i18next';
import {
  UPDATE_HOSPTIAL_MUTATION,
  UPDATE_HOSPITAL_EMAIL,
  SEND_PASSWORD_RESET_EMAIL,
} from "../../queries/hospital";


const HospitalInformationView = ({ hospital }) => {

  const { t } = useTranslation();

  const drop = useRef(null);

  const [updateHospital] = useMutation(UPDATE_HOSPTIAL_MUTATION);
  const [updateHospitalEmail] = useMutation(UPDATE_HOSPITAL_EMAIL);
  const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);
  const setTableUpdated = useStore(state => state.setTableUpdated);
  const history = useHistory();

  const initialValues = {
    email: hospital.email,
    name: hospital.name,
    phoneNumber: hospital.phoneNumber,
  };

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('register_hospital.warning.name_required')),
    email: Yup.string()
      .required(t('register_hospital.warning.email_required'))
      .email(t('register_hospital.warning.email_mispattern')),
    phoneNumber: Yup.string()
      .matches(
        /^[0-9]{7,}$/,
        t('register_hospital.warning.phone_mispattern')
      )
      .nullable(),
  });

  const onSubmit = async (values) => {
    try {

      if (hospital.email !== values.email) {
        await updateHospitalEmail({
          variables: {
            email: values.email,
            uid: hospital.firebase_id,
          }
        });
        await updateHospital({
          variables: {
            id: hospital.id,
            name: values.name,
            phoneNumber: values.phoneNumber,
            email: values.email,
            emailVerified: false,
          },
        });

      } else {
        await updateHospital({
          variables: {
            id: hospital.id,
            name: values.name,
            phoneNumber: values.phoneNumber,
            email: values.email,
            emailVerified: hospital.emailVerified,
          },
        });
      }

      setSubmitting(false);
      setTableUpdated(false);
      history.goBack();
    } catch (error) {
      toast(error.message, { type: "error" });
      setSubmitting(false);
      setErrors(error);
      window.alert(
        t('hospital_information.error.edit')
      );
      resetForm();
    }
  };

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues,
    onSubmit,
  });

  const {
    setSubmitting,
    setErrors,
    resetForm,
    isSubmitting,
    handleSubmit,
    errors,
  } = formik;

  const handleChangePwd = async () => {
    window.alert("Please follow the instructions sent to the registered email to reset the password.");
    try {
      await sendPasswordResetEmail({
        variables: {
          email: hospital.email,
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="app" ref={drop}>
      <ToastContainer />
      <FormikProvider value={formik}>
        <Form>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('hospital_information.form.name')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.name}</div>
            </span>
            <Field name="name" placeholder="" />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('hospital_information.form.email')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.email}</div>
            </span>
            <Field name="email" placeholder="" />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('hospital_information.form.telephone')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.phoneNumber}</div>
            </span>
            <Field name="phoneNumber" placeholder="" />
          </label>

          <button
            className="mybutton"
            style={{ width: '45%' }}
            type="submit"
            disabled={isSubmitting}
            onClick={handleChangePwd}
          >
            {t('hospital_information.submit.change_password')}
          </button>

          <button
            className="mybutton"
            style={{ width: '45%' }}
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting
              ? t('hospital_information.submit.loading')
              : t('hospital_information.submit.edit')
            }
          </button>

        </Form>
      </FormikProvider>
    </div>
  );
};

export default HospitalInformationView;
