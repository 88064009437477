import create from 'zustand'
import { configurePersist } from 'zustand-persist'

const { persist, purge } = configurePersist({
  storage: localStorage,
})

const useStore = create(
  persist({
    key: 'nav', // required, child key of storage
    allowlist: ['page'], // optional, will save everything if allowlist is undefined
  },
  set => ({

    tableName: 'patients',
    setTableName: (payload) => set({ tableName: payload }),
    tableUpdated: true,
    setTableUpdated: (payload) => set({ tableUpdated: payload }),
    pageHosptial: 0,
    setPageHospital: (payload) => set({ pageHospital: payload }),
  }))
)

export { persist, purge, useStore };

// export default useStore;