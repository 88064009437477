import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { Typography, Stack, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';

import {
  DefaultPage,
  LoginPage,
  HospitalsTablePage, RegisterNewHospitalPage, HospitalPage, 
  PharmacyTablePage, RegisterNewPharmacyPage, PharmacyPage,
  ContentPage,
  // DashboardPage,
  // PatientsTablePage,
  // StaffsTablePage,
  // PatientPage,
  // StaffPage,
  // RegisterNewPatientPage,
  // VideoReporterPage,
  // ChatPage,
  // RegisterNewStaffPage,
} from "./pages";

import colors from './utilities/colors';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospital, faPills, faLanguage, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { faBookMedical } from '@fortawesome/free-solid-svg-icons'
// import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import {
  PrivateRoute,
  // DefaultPrivateRoute,
  authContext
} from "../firebase/AuthContext";
import { purge } from "./store";
import { useTranslation } from 'react-i18next';
import useBreakpoints from './utilities/useBreakpoint';

const NavSide = (props) => {

  const { t, i18n } = useTranslation();
  const context = useContext(authContext);
  const point = useBreakpoints();

  return (
    <SideNav
      style={{
        backgroundColor: colors.primary,
        width: 10,
      }}
      expanded={props.expanded}
      onToggle={props.setExpanded}
      onSelect={(selected) => {
        var to = '';
        if (selected.startsWith('nav-')) {
          to = '/' + selected.replace('nav-', '');
          if (props.location.pathname !== to) {
            props.history.push(to);
            if (point === 'xs') {
              props.setExpanded(false);
            }
          }
        }
        else if (selected.startsWith("lang-")) {
          to = selected.replace('lang-', '');
          i18n.changeLanguage(to);
        }
        else if (selected === "logout") {
          context.signOut();
          props.history.push('/login');
          purge();
        }
      }}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected="nav-hospitals" >
        <NavItem eventKey="nav-hospitals" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faHospital} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>
            {t('nav.hospitals_list')}
          </NavText>
        </NavItem>
        <NavItem eventKey="nav-pharmacy" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faPills} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>
            {t('nav.pharmacy_list')}
          </NavText>
        </NavItem>
        <NavItem eventKey="nav-information" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faBookMedical} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>
            {t('nav.tb_information')}
          </NavText>
        </NavItem>
        <div style={{ height: 20 }} />
        <NavItem eventKey="language" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faLanguage} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>
            {t('nav.change_langiage')}
          </NavText>
          <NavItem eventKey="lang-th" style={{ padding: 3 }}>
            <NavText style={{ fontSize: 16, paddingLeft: 40 }}>
              ภาษาไทย
            </NavText>
          </NavItem>
          <NavItem eventKey="lang-en" style={{ padding: 3 }}>
            <NavText style={{ fontSize: 16, paddingLeft: 40 }}>
              English
            </NavText>
          </NavItem>
        </NavItem>
        <NavItem eventKey="logout" style={{ padding: 0 }}>
          <NavIcon style={{ fontSize: 20 }}>
            <FontAwesomeIcon icon={faSignOut} />
          </NavIcon>
          <NavText style={{ fontSize: 20 }}>
            {t('nav.signout')}
          </NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  )
}

const NavTop = (props) => {
  return (
    <Stack direction="row" spacing={2}
      style={{ backgroundColor: colors.primary, height: 60, color: 'white', fontSize: 30 }}
    >
      <IconButton
        style={{ color: 'white', padding: 20,  }}
        onClick={() => { props.setExpanded(true) }}
      >
        <Menu sx={{ fontSize: 27 }} />
      </IconButton>
      <Typography style={{
        fontWeight: 600, fontSize: 24,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: "center"
      }}>
        TBVDOT
      </Typography>
    </Stack>
  )
}

const MainApp = () => {

  const context = useContext(authContext);
  const [expanded, setExpanded] = useState(false);
  const point = useBreakpoints();

  return (
    <Router>
      <Route render={({ location, history }) => (
        <React.Fragment>
          <div style={{ height: '100vh' }} >
            {
              context.isUserSignedIn &&
              <>
                {point === 'xs' &&
                  <NavTop
                    expanded={expanded}
                    setExpanded={setExpanded}
                  />
                }
                {(point !== 'xs' || expanded) &&
                  <NavSide
                    expanded={expanded}
                    setExpanded={setExpanded}
                    location={location}
                    history={history}
                  />
                }
              </>
            }

            <div
              style={context.isUserSignedIn ? {
                marginLeft: expanded ? 240 : point === 'xs' ? 0 : 64,
                padding: '15px 20px 0 20px',
                height: '100vh',
                overflowY: 'scroll',
              } : {}}
            >
              <Route path="/login" exact component={LoginPage} />

              <PrivateRoute path="/" exact component={DefaultPage} />

              <PrivateRoute path="/hospitals" component={HospitalsTablePage} />
              <PrivateRoute path="/hospitals_registeration" component={RegisterNewHospitalPage} />
              <PrivateRoute path="/hospital/:hospitalId" component={HospitalPage} />

              <PrivateRoute path="/pharmacy" exact component={PharmacyTablePage} />
              <PrivateRoute path="/pharmacy_registeration" component={RegisterNewPharmacyPage} />
              <PrivateRoute path="/pharmacy/:pharmacyId" component={PharmacyPage} />

              <PrivateRoute path="/information" component={ContentPage} />
            </div>
          </div>
        </React.Fragment>
      )}
      />
    </Router>
  );
};

export default MainApp;
