import React from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./firebase/ApolloClient";

import "./App.css";
import { useStore } from "./app/store";
import { AuthProvider } from "./firebase/AuthContext";
import "./app/utilities/i18n";

import MainApp from "./app/main";

const App = () => {
  useStore();

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <MainApp />
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
