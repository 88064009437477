import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import auth from "./index";

const HTTPS_URL = "https://hasura.tbvdot.com/v1/graphql";
const WSS_URL = "wss://hasura.tbvdot.com/v1/graphql";

const getHeaders = async () => {
  const headers = {};
  const token = await auth.currentUser?.getIdToken();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

function link() {
  const httpLink = new HttpLink({
    uri: HTTPS_URL,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      if (networkError.message === "Network request failed") {
        console.log("ไม่สามารถเชื่อมต่อกับระบบ กรุณาลองใหม่ภายหลัง");
      }
      console.log(
        `Network error: ${networkError.message} Code:${networkError.statusCode}`
      );
    }
  });
  
  const authLink = setContext(async (req, {headers}) => {
    const token = await auth.currentUser?.getIdToken();
    return {
      ...headers,
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const httpWithAuth = authLink.concat(httpLink);

  const wsLink = new WebSocketLink({
    uri: WSS_URL,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        return { headers: await getHeaders() };
      },
    },
  });

  return ApolloLink.from([
    errorLink,
    split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpWithAuth
  )]);
}

const client = new ApolloClient({
  link: link(),
  cache: new InMemoryCache(),
});

client.onResetStore(async () => {
  client.setLink(link());
});

export default client;
