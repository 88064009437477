import React, { useCallback, useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { Link } from "react-router-dom";
import "react-table-6/react-table.css";
import { useStore } from "../store";
import { useQuery, useMutation } from "@apollo/client";
import HOSPITAL_QUERY, {
  CHECK_VERIFICATION_STATUSES,
} from "../queries/hospital";

import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";

const PATIENT_QUERY = gql`
  query patients {
    patients {
      hospital_id
    }
  }
`;

const STAFF_QUERY = gql`
  query staffs {
    staffs {
      hospital_id
    }
  }
`;

const HospitalList = () => {
  // Set page number inside zustand store
  const pageHospital = useStore((state) => state.pageHospital);
  const setPageHospital = useStore((state) => state.setPageHospital);
  const tableUpdated = useStore((state) => state.tableUpdated);
  const setTableUpdated = useStore((state) => state.setTableUpdated);

  const [hospitalsData, setHospitalsData] = useState([]);
  const [searchString, setSearchString] = useState("");

  const hospitalQuery = useQuery(HOSPITAL_QUERY);
  const patientQuery = useQuery(PATIENT_QUERY);
  const staffQuery = useQuery(STAFF_QUERY);
  const [verificationStatusesMutation] = useMutation(
    CHECK_VERIFICATION_STATUSES
  );

  const { t } = useTranslation();

  const columns = [
    {
      Header: <b>{t("hospitals_list.table.name")}</b>,
      accessor: "name",
    },
    {
      Header: <b>{t("hospitals_list.table.email")}</b>,
      accessor: "email",
    },
    {
      Header: <b>{t("hospitals_list.table.telephone")}</b>,
      accessor: "phoneNumber",
    },
    {
      Header: <b>{t("hospitals_list.table.abbreviation")}</b>,
      accessor: "shortName",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: <b>{t("hospitals_list.table.num_patients")}</b>,
      accessor: "num_patients",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: <b>{t("hospitals_list.table.num_nurses")}</b>,
      accessor: "num_nurses",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: <b>{t("hospitals_list.table.email_verified")}</b>,
      accessor: "emailVerified",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>{row.value ? "Yes" : "No"}</div>
      ),
    },
    {
      Header: <b>{t("nurses_list.table.edit")}</b>,
      accessor: "edit",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
  ];

  // refetch after adding
  useEffect(() => {
    if (!tableUpdated) {
      setTableUpdated(true);
      hospitalQuery.refetch();
    }
  }, [tableUpdated, setTableUpdated, hospitalQuery]);

  const onSearchFocus = (e) => {
    setPageHospital(0);
  };

  const onSearch = (e) => {
    setSearchString(e.target.value);
  };

  const onPageChange = (newPage) => {
    setPageHospital(newPage);
  };

  useEffect(() => {
    if (
      !hospitalQuery.loading &&
      hospitalQuery.data &&
      hospitalQuery.data.hospitals &&
      !patientQuery.loading &&
      patientQuery.data &&
      !staffQuery.loading &&
      staffQuery.data
    ) {
      // TODO: refactor this to allow for more than 100 hospitals
      const checkVerificationStatuses = async (hospitalData) => {
        // check for verification statuses
        const unverifiedUids = hospitalData
          .filter((hospital) => !hospital.emailVerified)
          .map((hospital) => hospital.firebase_id);
  
        if (unverifiedUids.length > 0) {
          const results = await verificationStatusesMutation({
            variables: {
              uids: unverifiedUids,
            },
          });
          if (results.data) {
            const {
              checkVerificationStatuses: { verificationStatuses },
            } = results.data;
  
            const veriStatuses = verificationStatuses.map((status) => {
              const [firebaseId, veriStatus] = status.split(":");
              return { firebase_id: firebaseId, emailVerified: veriStatus };
            });
          
            const newHospitalData = hospitalData.map((hospital) => {
              // Find only the ids of the hospitals that was asked for verification
              const status = veriStatuses.find(
                (status) => status.firebase_id === hospital.firebase_id
              );
              if (status) {
                const newHospital = { ...hospital, emailVerified: (status.emailVerified === 'true') };
                return newHospital;
              }
              return hospital;
            });
  
            console.log("getting new statuses");
            setHospitalsData(newHospitalData);
          }
        }
      };
      // sort by name
      var data_hospital = JSON.parse(
        JSON.stringify(hospitalQuery.data.hospitals)
      );
      data_hospital.sort((a, b) => (a.name > b.name ? 1 : -1));

      checkVerificationStatuses(data_hospital);

      const hospitals = data_hospital.map((hospital) => {
        // mutable object from immutable object
        // const unfrozenStaff = JSON.parse(JSON.stringify(staff));

        let patientCount = 0;
        for (let i = 0; i < patientQuery.data.patients.length; i++) {
          if (
            patientQuery.data.patients[i].hospital_id === hospital.firebase_id
          ) {
            patientCount += 1;
          }
        }
        hospital.num_patients = patientCount;

        let nurseCount = 0;
        for (let i = 0; i < staffQuery.data.staffs.length; i++) {
          if (staffQuery.data.staffs[i].hospital_id === hospital.firebase_id) {
            nurseCount += 1;
          }
        }
        hospital.num_nurses = nurseCount;

        hospital.verified = true;
        hospital.edit = (
          <Link
            to={`/hospital/${hospital.id}`}
            style={{ color: "#55d", textDecoration: "none" }}
            state={pageHospital}
          >
            {t("hospitals_list.table.edit")}
          </Link>
        );
        return hospital;
      });
      setHospitalsData(hospitals);
    }
  }, [
    hospitalQuery.loading,
    hospitalQuery.data,
    patientQuery.loading,
    patientQuery.data,
    staffQuery.loading,
    staffQuery.data,
    t,
    pageHospital,
    verificationStatusesMutation
  ]);

  const getFilteredData = useCallback(() => {
    if (searchString) {
      let lowerSearchString = searchString.toLowerCase();
      return hospitalsData.filter((row) => {
        const emailVerified = row.emailVerified ? "yes" : "no";
        return (
          row.name?.toLowerCase().includes(lowerSearchString) ||
          row.phoneNumber?.includes(lowerSearchString) ||
          row.email?.toLowerCase().toLowerCase().includes(lowerSearchString) ||
          row.shortName?.toLowerCase().includes(lowerSearchString) ||
          row.num_patients?.toString().toLowerCase().includes(lowerSearchString) ||
          row.num_nurses?.toString().toLowerCase().includes(lowerSearchString) ||
          emailVerified.includes(lowerSearchString)
        );
      });
    } else {
      return hospitalsData;
    }
  }, [searchString, hospitalsData]);

  return (
    <div>
      <div className="head-topic">
        <div style={{ display: "flex", alignItems: "center" }}>
          {t("hospitals_list.search")}:{"  "}
          <input
            className="searchbox"
            value={searchString}
            onFocus={onSearchFocus}
            onChange={onSearch}
          />
        </div>
        <div style={{ flex: 1 }} />
        <Link
          className="add-patient"
          to={`/hospitals_registeration`}
          style={{ textDecoration: "none" }}
        >
          {t("hospitals_list.add_hospital")}
        </Link>
      </div>
      <ReactTable
        data={getFilteredData()}
        columns={columns}
        defaultPageSize={10}
        autoResetPage={false}
        className="-striped -highlight"
        page={pageHospital}
        onPageChange={onPageChange}
        style={{
          // background: '#F0F8FF',
          borderRadius: "20px",
          overflow: "hidden",
          padding: "20px",
        }}
      />
    </div>
  );
};

export default HospitalList;
