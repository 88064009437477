import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { HospitalInformation } from "../components/InformationView";
import { useRouteMatch } from "react-router-dom"
import { gql } from "@apollo/client";
import { useTranslation } from 'react-i18next';

const HOSPITAL_QUERY = gql`
  query hospital($id:uuid!) {
    hospitals_by_pk(id:$id) {
      email
      firebase_id
      name
      phoneNumber
      shortName
      id
    }
  }
`

const HospitalFC = () => {

  const { t } = useTranslation();

  const [hospital, setHospital] = useState(null);
  const match = useRouteMatch('/hospital/:hospitalId')
  const { loading, error, data } = useQuery(HOSPITAL_QUERY, {
    variables: {
      id: match.params.hospitalId
    }
  });

  useEffect(() => {
    console.log('hospital', hospital)
  })

  useEffect(() => {
    if (!loading && data) {
      setHospital(data.hospitals_by_pk);
    }
  }, [loading, data]);

  if (hospital && !loading) {
    return (
      <Fragment>
        <h1>{t('hospital_information.topic')}</h1>
        <div className="panel">
          <div className="half-panel">
            <HospitalInformation hospital={hospital} />
          </div>
        </div>
      </Fragment>)
  } else if (error) {
    return <div>Error...{error.message}</div>
  } else {
    return <div>Loading...</div>
  }
}

export default HospitalFC;
