import React, { useContext } from "react";
import { Button, TextField } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import auth from "../../firebase";
import { authContext } from "../../firebase/AuthContext";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("It should be an email")
    .required("This field is required"),
  password: Yup.string().required("This field is required"),
});

const LoginPage = () => {
  const history = useHistory();
  const context = useContext(authContext);

  const signinHandler = async (values) => {
    try {
      const { email, password } = values;
      await auth.signInWithEmailAndPassword(email, password);
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      if (!idTokenResult
        .claims["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"]
        .includes("superuser")) {
          await auth.signOut();
          window.alert("Only admin can login to this site.");
        } else {
          context?.setIsUserSignedIn(true);
          localStorage.setItem('signed_in', '1');
          history.push("/");
        }  
    } catch (error) {
      localStorage.setItem('signed_in', '0');
      console.log(error);
    }
  };

  // const userLoggedInHandler = (
  //   data
  // ) => {
  //   if (data?.loginStaff?.accessToken) {
  //     context?.setIsUserSignedIn(true);
  //     history.push('/');
  //   }
  // };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: '100vh',
        backgroundImage: `url("/pexels-jeshootscom-576831.jpg")`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          padding: 40,
          paddingTop: 80,
          paddingBottom: 80,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderRadius: 50,
        }}
      >

        <img src="/vdot.ico" alt="" width={150} height={150} />
        <div style={{fontSize: 25, color: 'black', fontWeight: 'bold', padding: 30 }}>
          Hospital Administration
        </div>
        <div
          style={{
            backgroundColor: 'white',
            padding: 0,
            width: 360,
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={signinHandler}
          >
            {({
              values,
              touched,
              errors,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
              isInitialValid,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  error={!!(errors.email && touched.email)}
                  helperText={errors.email && touched.email && errors.email}
                  margin="normal"
                />
                <TextField
                  label="Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="password"
                  fullWidth
                  error={!!(errors.password && touched.password)}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                  margin="normal"
                />
                <div style={{height: 25}}/>

                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!isValid || !!isInitialValid}
                >
                  Sign In
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
