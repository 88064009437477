import React, { createContext, useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../firebase/index";
import client from "../firebase/ApolloClient";


export const authContext = createContext(null);

export function AuthProvider({ children }) {
  const [isUserSignedIn, setIsUserSignedIn] = useState(parseInt(localStorage.getItem('signed_in')));
  const [user, setUser] = useState({ uid: '', displayName: '', email: '' });

  const clearUser = async () => {
    localStorage.setItem('signed_in', '0');
    await auth.signOut();
    await client.resetStore();
    setIsUserSignedIn(false);
    setUser({ uid: '', displayName: '', email: '' });
  }

  useEffect(() => {
    auth.onIdTokenChanged(
      async user => {
        if (user) {
          try {
            const result =  await user.getIdTokenResult();
            if (result.claims['https://hasura.io/jwt/claims']) {
              setIsUserSignedIn(true);
              setUser(user);
              await client.resetStore();
            } else {
              const endpoint = 'https://us-central1-tbvdot-abfce.cloudfunctions.net/refreshTokenSU';
              fetch(`${endpoint}?uid=${user.uid}`).then(async (res) => {
                if (res.status === 200) {
                  await user.getIdToken(true);
                  setIsUserSignedIn(true);
                  setUser(user);
                  await client.resetStore();
                } else {
                  await clearUser();
                  res.json().then((e) => { throw e });
                }
              })
            }
        } catch (e) {
          await clearUser();
          console.log(e);
        }
      } else {
        clearUser();
      }
    })
   }   
  , []);

  const signOut = async () => {
    await clearUser();
  };

  return (
    <authContext.Provider
      value={{
        isUserSignedIn,
        setIsUserSignedIn,
        signOut,
        user,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

export function PrivateRoute({
    component: Component,
    ...rest
  }) {
    const context = useContext(authContext);
    return (
      <Route
        {...rest}
        render={
          props =>
            context.isUserSignedIn ? (
              <Component {...props} {...rest} />
            ) : (
              <Redirect to="/login" />
            )
        }
      />
    );
  }
  