import { gql } from "@apollo/client";

export default gql`
  query hospital {
    hospitals {
      email
      firebase_id
      name
      phoneNumber
      shortName
      emailVerified
      id
    }
  }
`;

export const CHECK_VERIFICATION_STATUSES = gql`
  mutation CheckVerificationStatuses($uids: [String!]!) {
    checkVerificationStatuses(uids: $uids) {
      verificationStatuses
    }
  }
`;

export const SIGNUP_HOSPITAL_MUTATION = gql`
  mutation SignUpHospitalFB(
    $displayName: String!
    $email: String!
    $password: String!
  ) {
    signUpHospital(
      credentials: {
        displayName: $displayName
        email: $email
        password: $password
      }
    ) {
      id
      email
      displayName
    }
  }
`;

export const INSERT_HOSPITAL_MUTATION = gql`
  mutation InsertHospital(
    $email: String!
    $emailVerified: Boolean = false
    $firebase_id: String!
    $name: String!
    $phoneNumber: String
    $shortName: String!
  ) {
    insert_hospitals_one(
      object: {
        email: $email
        emailVerified: $emailVerified
        firebase_id: $firebase_id
        name: $name
        phoneNumber: $phoneNumber
        shortName: $shortName
      }
    ) {
      id
    }
  }
`;

export const CREATE_BUCKET_MUTATION = gql`
  mutation createBucket($bucketId: String!) {
    createBucket(bucketId: $bucketId) {
      success
    }
  }
`;

export const UPDATE_HOSPTIAL_MUTATION = gql`
  mutation UpdateHospital(
    $id: uuid!
    $email: String!
    $emailVerified: Boolean = false
    $name: String = ""
    $phoneNumber: String = ""
  ) {
    update_hospitals_by_pk(
      pk_columns: { id: $id }
      _set: {
        email: $email
        emailVerified: $emailVerified
        name: $name
        phoneNumber: $phoneNumber
      }
    ) {
      email
      emailVerified
      firebase_id
      phoneNumber
      shortName
      name
    }
  }
`;

export const UPDATE_HOSPITAL_EMAIL = gql`
  mutation UpdateHospitalEmail($email: String!, $uid: String!) {
    updateHospitalEmail(credentials: { email: $email, uid: $uid }) {
      email
      id
    }
  }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(credentials: {email: $email}) {
      success
    }
  }
`;
