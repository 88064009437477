// import "./RegisterView.css";
import React, { useRef, useState } from "react";
import { FormikProvider, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SIGNUP_HOSPITAL_MUTATION,
  INSERT_HOSPITAL_MUTATION,
  CREATE_BUCKET_MUTATION,
} from "../../queries/hospital";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useStore } from "../../store";
import { useTranslation } from 'react-i18next';
import hospitals from '../../utilities/Hospital.json';

const initialValues = {
  name: "",
  email: "",
  phoneNumber: "",
  abbreviation: "",
  hospitalId: "",
  password: "",
  confirm_password: "",
};

const RegisterForm = () => {

  const { t } = useTranslation();

  const drop = useRef(null);
  const [signUpHospital] = useMutation(SIGNUP_HOSPITAL_MUTATION);
  const [insertHospital] = useMutation(INSERT_HOSPITAL_MUTATION);
  const [createBucket] = useMutation(CREATE_BUCKET_MUTATION);
  const setTableUpdated = useStore(state => state.setTableUpdated);
  const [id5, setId5] = useState("");

  const history = useHistory();

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('register_hospital.warning.name_required')),
    email: Yup.string()
      .required(t('register_hospital.warning.email_required'))
      .email(t('register_hospital.warning.email_mispattern')),
    phoneNumber: Yup.string()
      .matches(
        /^[0-9]{7,}$/,
        t('register_hospital.warning.phone_mispattern')
      ),
    abbreviation: Yup.string()
      .required(t('register_hospital.warning.abb_required'))
      .matches(
        /^[a-zA-Z0-9]{0,7}$/,
        t('register_hospital.warning.abb_mispattern')
      ),
    password: Yup.string()
      .required(t('register_hospital.warning.pwd_required'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        t('register_hospital.warning.pwd_mispattern')
      ),
    confirm_password: Yup.string()
      .required(t('register_hospital.warning.cfm_pwd_required'))
      .oneOf([
        Yup.ref('password'), null],
        t('register_hospital.warning.cfm_pwd_mismatched')
      )
  });

  const handleId5Change = (event) => {
    let s = event.target.value;
    s = s.replace(/\D/g, "");
    if (s.length === 6 && id5.length === 5) {
      s = s.substring(0, 4) + s.substring(4 + 1);
    }
    if (s.length > 5) {
      s = s.substring(0, 5);
    }
    setId5(s);

    if (hospitals.hasOwnProperty(s)) {
      setValues((prev) =>({...prev, name: hospitals[s]}))
    }
  }

  const onSubmit = async (values) => {
    console.log('values', values);
    try {

      const results = await signUpHospital({
        variables: {
          displayName: values.name,
          email: values.email,
          password: values.password,
        }
      });

      const { signUpHospital: {
        id,
      } } = results.data;

      const insertResults = await insertHospital({
        variables: {
          email: values.email,
          emailVerified: false,
          firebase_id: id,
          name: values.name,
          phoneNumber: values.phoneNumber,
          shortName: values.abbreviation,
        }
      });

      const { insert_hospitals_one: {
        id: hospitalId,
      } } = insertResults.data;

      await createBucket({
        variables: {
          bucketId: hospitalId,
        }
      });

      setSubmitting(false);
      setTableUpdated(false);
      history.goBack();
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      setErrors(error);
      window.alert(
        t('register_hospital.error.signup')
      );
      resetForm();
    };
  };

  const formik = useFormik({
    validationSchema: SignupSchema,
    initialValues,
    onSubmit,
  });

  const {
    setSubmitting,
    setErrors,
    resetForm,
    isSubmitting,
    handleSubmit,
    errors,
    setValues
  } = formik;

  return (
    <div className="app" ref={drop}>
      <ToastContainer />

      <FormikProvider value={formik}>
        <Form>

          <label>
            <span style={{ display: 'inline-flex' }}>
              ID5
              <div style={{ color: 'gray', marginLeft: 10 }}>
                {t('register_hospital.warning.id5_suggest')}
                </div>
            </span>
            <Field name="id5" placeholder="" 
              value = {id5}
              onChange = {handleId5Change}
            />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('register_hospital.form.name')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.name}</div>
            </span>
            <Field name="name" placeholder="" />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('register_hospital.form.email')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.email}</div>
            </span>
            <Field name="email" placeholder="" />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('register_hospital.form.telephone')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.phoneNumber}</div>
            </span>
            <Field name="phoneNumber" placeholder="" />
          </label>

          <label>
            <span>
              {t('register_hospital.form.abbreviation')} {t('register_hospital.warning.abb_cannot_change_later')}
              <div style={{ color: 'red' }}>{errors.abbreviation}</div>
            </span>
            <Field name="abbreviation" placeholder="" />
          </label>

          <label>
            <span>
              {t('register_hospital.form.password')}
              <div style={{ color: 'red' }}>{errors.password}</div>
            </span>
            <Field name="password" placeholder="" />
          </label>

          <label>
            <span style={{ display: 'inline-flex' }}>
              {t('register_hospital.form.confirm_password')}
              <div style={{ color: 'red', marginLeft: 10 }}>{errors.confirm_password}</div>
            </span>
            <Field name="confirm_password" placeholder="" />
          </label>

          <button
            className="mybutton"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting
              ? t('register_hospital.submit.loading')
              : t('register_hospital.submit.register')
            }
          </button>

        </Form>
      </FormikProvider>

    </div>
  );
};

export default RegisterForm;
